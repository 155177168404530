.uploadedImage {
  max-width: 100%;
  padding: 5px;
}

.form-control-file {
  color: white;
}

.dropdown,
.selection {
  font-size: small;
}
.form-label {
  font-size: small;
}

.form-control {
  font-size: small;
}
